exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-year-in-review-js": () => import("./../../../src/pages/year-in-review.js" /* webpackChunkName: "component---src-pages-year-in-review-js" */),
  "component---src-templates-letter-js-content-file-path-src-year-in-review-2021-mdx": () => import("./../../../src/templates/letter.js?__contentFilePath=/opt/build/repo/src/year-in-review/2021.mdx" /* webpackChunkName: "component---src-templates-letter-js-content-file-path-src-year-in-review-2021-mdx" */),
  "component---src-templates-letter-js-content-file-path-src-year-in-review-2022-mdx": () => import("./../../../src/templates/letter.js?__contentFilePath=/opt/build/repo/src/year-in-review/2022.mdx" /* webpackChunkName: "component---src-templates-letter-js-content-file-path-src-year-in-review-2022-mdx" */),
  "component---src-templates-letter-js-content-file-path-src-year-in-review-2023-mdx": () => import("./../../../src/templates/letter.js?__contentFilePath=/opt/build/repo/src/year-in-review/2023.mdx" /* webpackChunkName: "component---src-templates-letter-js-content-file-path-src-year-in-review-2023-mdx" */),
  "component---src-templates-letter-js-content-file-path-src-year-in-review-2024-mdx": () => import("./../../../src/templates/letter.js?__contentFilePath=/opt/build/repo/src/year-in-review/2024.mdx" /* webpackChunkName: "component---src-templates-letter-js-content-file-path-src-year-in-review-2024-mdx" */)
}

